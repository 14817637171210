import React from 'react';
import ReactPlayer from 'react-player';

var Video = () => {
    return(
		<div>
			<section id="video">
				<div className="container">
					<div className="row">
						<div className="col-lg-12">
							<div className="row">
								<h2 className="uppercase">Myers Running Camp Video</h2>
								<p className="lead">See what it's like to be a part of Myers Running Camp!</p>
							</div>
						</div>
						<div className="col-lg-8 col-lg-offset-2">
							<div className="row player-wrapper">
								<ReactPlayer
									className='react-player'
									url='https://youtu.be/K6gcYFXE8gE'
									width="100%"
									height="100%"
								/>
							</div>
						</div>
					</div>
				</div>
			</section>
		</div>
    );
}

export default Video;