import React from 'react';

var About = () => {
	return(
	    <div>
			<section id="info">
				<div className="container">
					<div className="row">	
						<div className="col-lg-12 text-center">
							<h1>2020 Myers Running Camp</h1>
							<p className="lead">The 2020 Myers Running Camp will be held from July 10 – July 13, 2020. This will be an overnight camp, located at Camp Oty’Okwa near Old Mans Cave in beautiful Hocking Hills, Ohio. <br />The camp is an Ohio cross country overnight camp, open to all students entering grades 7-12 in the fall of 2020.</p>
						</div>
						<div className="col-lg-10 col-lg-offset-1 col-md-12 text-center">
							<div className="row">
								<div className="feature col-lg-4 col-md-4 col-sm-4">
									<i className="pe-4x pe-7s-refresh-2" />
									<h4>Camp Goal</h4>
									<p>Our goals are to be accomplished through a “learning and training” approach to running. Campers will be introduced to various training methods and drills used by elite athletes and then have the opportunity to practice those techniques alongside other high school, college, and elite athletes.</p>
								</div>
								<div className="feature col-lg-4 col-md-4 col-sm-4">
									<i className="pe-4x pe-7s-micro" />
									<h4>Speakers</h4>
									<p>Learn from speakers from the various levels of college programs, including college coaches and athletes. There will also be world level athletes, running shoes specialists, and our featured athlete Rob Myers. We will also engage in Running form analysis, Proper running form drills, Core strengthening drills, Warm up drills, Stretching drills, and Cross Training Techniques.</p>
								</div>
								<div className="feature col-lg-4 col-md-4 col-sm-4">
									<i className="pe-4x pe-7s-cup" />
									<h4>Perform</h4>
									<p>Take what you have learned and experienced at the Myers Running Camp and incorporate it into your everyday training to help perform better during your cross country or track seasons!</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
	    </div>
	);
 }
 
 export default About;