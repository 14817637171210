import React from 'react';

var Schedule = () => {
    return(
        <div>
            <section id="schedule">
			<div className="container">
				<div className="row">
				<div className="col-lg-12">
				<h2 className="uppercase">Myers Running Camp Schedule</h2>
				<p className="lead">Please note, this is a tentative schedule, while the overall format won’t change much, the activities, speakers, and drills could change or move around. It is our goal to keep the campers busy, involved, and interacting with each other.</p>
				<ul id="myTab" className="nav nav-tabs" role="tablist">
					<li role="presentation" className="active"><a href="#day1" id="day1-tab" role="tab" data-toggle="tab" aria-controls="day1" aria-expanded="true">Day 1</a></li>
					<li role="presentation" className=""><a href="#day2" role="tab" id="day2-tab" data-toggle="tab" aria-controls="day2" aria-expanded="false">Day 2</a></li>
					<li role="presentation" className=""><a href="#day3" role="tab" id="day3-tab" data-toggle="tab" aria-controls="day3" aria-expanded="false">Day 3</a></li>
					<li role="presentation" className=""><a href="#day4" role="tab" id="day4-tab" data-toggle="tab" aria-controls="day4" aria-expanded="false">Day 4</a></li>
				</ul>
				<div id="myTabContent" className="tab-content">
					<div role="tabpanel" className="tab-pane fade active in" id="day1" aria-labelledby="day1-tab">
						<div className="panel-group" id="accordion1" role="tablist" aria-multiselectable="true">
							<div className="panel panel-default">
								<div className="panel-heading" role="tab" id="heading1">
									<div className="row">
										<div className="col-lg-2 col-md-2 col-sm-2">
										<p className="date">12:00-­1:00pm</p>
										</div>
										<div className="col-lg-10 col-md-10 col-sm-10">
										<h4 className="panel-title">Camp Check In</h4>
										</div>
									</div>
								</div>
							</div>
							<div className="panel panel-default">
								<div className="panel-heading" role="tab" id="heading2">
									<div className="row">
										<div className="col-lg-2 col-md-2 col-sm-2">
										<p className="date">1:15-­2:00pm</p>
										</div>
										<div className="col-lg-10 col-md-10 col-sm-10">
										<h4 className="panel-title">Welcome meeting, Counselor introductions and Team introductions</h4>
										</div>
									</div>
								</div>
							</div>
							<div className="panel panel-default">
								<div className="panel-heading" role="tab" id="heading3">
								<div className="row">
										<div className="col-lg-2 col-md-2 col-sm-2">
										<p className="date">2:30pm</p>
										</div>
										<div className="col-lg-10 col-md-10 col-sm-10">
										<h4 className="panel-title">Work Shop #1 - Dynamic Warm Ups</h4>
										</div>
									</div>
								</div>
							</div>
							<div className="panel panel-default">
								<div className="panel-heading" role="tab" id="heading4">
									<div className="row">
										<div className="col-lg-2 col-md-2 col-sm-2">
										<p className="date">3:00-­5:00pm</p>
										</div>
										<div className="col-lg-10 col-md-10 col-sm-10">
										<h4 className="panel-title">Group Run & Stretching</h4>
										</div>
									</div>
								</div>
							</div>
							<div className="panel panel-default">
								<div className="panel-heading" role="tab" id="heading5">
									<div className="row">
										<div className="col-lg-2 col-md-2 col-sm-2">
										<p className="date">5:30-­6:30pm</p>
										</div>
										<div className="col-lg-10 col-md-10 col-sm-10">
										<h4 className="panel-title">Dinner</h4>
										</div>
									</div>
								</div>
							</div>
							<div className="panel panel-default">
								<div className="panel-heading" role="tab" id="heading6">
									<div className="row">
										<div className="col-lg-2 col-md-2 col-sm-2">
										<p className="date">6:30-7:15pm</p>
										</div>
										<div className="col-lg-10 col-md-10 col-sm-10">
										<h4 className="panel-title">Speaker</h4>
										</div>
									</div>
								</div>
							</div>
							<div className="panel panel-default">
								<div className="panel-heading" role="tab" id="heading7">
									<div className="row">
										<div className="col-lg-2 col-md-2 col-sm-2">
										<p className="date">7:30-­8:30pm</p>
										</div>
										<div className="col-lg-10 col-md-10 col-sm-10">
										<h4 className="panel-title">Team challenge #1</h4>
										</div>
									</div>
								</div>
							</div>
							<div className="panel panel-default">
								<div className="panel-heading" role="tab" id="heading8">
									<div className="row">
										<div className="col-lg-2 col-md-2 col-sm-2">
										<p className="date">8:30-10:00pm</p>
										</div>
										<div className="col-lg-10 col-md-10 col-sm-10">
										<h4 className="panel-title">Free Time</h4>
										</div>
									</div>
								</div>
							</div>
							<div className="panel panel-default">
								<div className="panel-heading" role="tab" id="heading9">
									<div className="row">
										<div className="col-lg-2 col-md-2 col-sm-2">
										<p className="date">10:30pm</p>
										</div>
										<div className="col-lg-10 col-md-10 col-sm-10">
										<h4 className="panel-title">Lights Out</h4>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div role="tabpanel" className="tab-pane fade in" id="day2" aria-labelledby="day2-tab">
						<div className="panel-group" id="accordion2" role="tablist" aria-multiselectable="true">
							<div className="panel panel-default">
								<div className="panel-heading" role="tab" id="heading10">
									<div className="row">
										<div className="col-lg-2 col-md-2 col-sm-2">
										<p className="date">7:00am</p>
										</div>
										<div className="col-lg-10 col-md-10 col-sm-10">
										<h4 className="panel-title">Wake Up Call - Get dressed for a run</h4>
										</div>
									</div>
								</div>
							</div>
							<div className="panel panel-default">
								<div className="panel-heading" role="tab" id="heading11">
									<div className="row">
										<div className="col-lg-2 col-md-2 col-sm-2">
										<p className="date">7:30am</p>
										</div>
										<div className="col-lg-10 col-md-10 col-sm-10">
										<h4 className="panel-title">Light pre-run breakfast</h4>
										</div>
									</div>
								</div>
							</div>
							<div className="panel panel-default">
								<div className="panel-heading" role="tab" id="heading12">
								<div className="row">
										<div className="col-lg-2 col-md-2 col-sm-2">
										<p className="date">8:00-8:40am</p>
										</div>
										<div className="col-lg-10 col-md-10 col-sm-10">
										<h4 className="panel-title">Speaker</h4>
										</div>
									</div>
								</div>
							</div>
							<div className="panel panel-default">
								<div className="panel-heading" role="tab" id="heading13">
									<div className="row">
										<div className="col-lg-2 col-md-2 col-sm-2">
										<p className="date">9:00am</p>
										</div>
										<div className="col-lg-10 col-md-10 col-sm-10">
										<h4 className="panel-title">Load Buses for Old Mans Cave to Rose Lake Run</h4>
										</div>
									</div>
								</div>
							</div>
							<div className="panel panel-default">
								<div className="panel-heading" role="tab" id="heading14">
									<div className="row">
										<div className="col-lg-2 col-md-2 col-sm-2">
										<p className="date">9:15-11:30am</p>
										</div>
										<div className="col-lg-10 col-md-10 col-sm-10">
										<h4 className="panel-title">Run to Rose Lake, and some free time to hike with your groups</h4>
										</div>
									</div>
								</div>
							</div>
							<div className="panel panel-default">
								<div className="panel-heading" role="tab" id="heading15">
									<div className="row">
										<div className="col-lg-2 col-md-2 col-sm-2">
										<p className="date">11:30-12:30pm</p>
										</div>
										<div className="col-lg-10 col-md-10 col-sm-10">
										<h4 className="panel-title">Picnic Lunch</h4>
										</div>
									</div>
								</div>
							</div>
							<div className="panel panel-default">
								<div className="panel-heading" role="tab" id="heading16">
									<div className="row">
										<div className="col-lg-2 col-md-2 col-sm-2">
										<p className="date">1:00pm</p>
										</div>
										<div className="col-lg-10 col-md-10 col-sm-10">
										<h4 className="panel-title">Load Buses to head back to camp</h4>
										</div>
									</div>
								</div>
							</div>
							<div className="panel panel-default">
								<div className="panel-heading" role="tab" id="heading17">
									<div className="row">
										<div className="col-lg-2 col-md-2 col-sm-2">
										<p className="date">2:00-3:15pm</p>
										</div>
										<div className="col-lg-10 col-md-10 col-sm-10">
										<h4 className="panel-title">Team Challenges</h4>
										</div>
									</div>
								</div>
							</div>
							<div className="panel panel-default">
								<div className="panel-heading" role="tab" id="heading18">
									<div className="row">
										<div className="col-lg-2 col-md-2 col-sm-2">
										<p className="date">3:15-4:45pm</p>
										</div>
										<div className="col-lg-10 col-md-10 col-sm-10">
										<h4 className="panel-title">Free Time / Pool</h4>
										</div>
									</div>
								</div>
							</div>
							<div className="panel panel-default">
								<div className="panel-heading" role="tab" id="heading19">
									<div className="row">
										<div className="col-lg-2 col-md-2 col-sm-2">
										<p className="date">5:00pm</p>
										</div>
										<div className="col-lg-10 col-md-10 col-sm-10">
										<h4 className="panel-title">Group Core Session</h4>
										</div>
									</div>
								</div>
							</div>
							<div className="panel panel-default">
								<div className="panel-heading" role="tab" id="heading19">
									<div className="row">
										<div className="col-lg-2 col-md-2 col-sm-2">
										<p className="date">5:45pm</p>
										</div>
										<div className="col-lg-10 col-md-10 col-sm-10">
										<h4 className="panel-title">Team Skit Practices</h4>
										</div>
									</div>
								</div>
							</div>
							<div className="panel panel-default">
								<div className="panel-heading" role="tab" id="heading20">
									<div className="row">
										<div className="col-lg-2 col-md-2 col-sm-2">
										<p className="date">6:30pm</p>
										</div>
										<div className="col-lg-10 col-md-10 col-sm-10">
										<h4 className="panel-title">Dinner</h4>
										</div>
									</div>
								</div>
							</div>
							<div className="panel panel-default">
								<div className="panel-heading" role="tab" id="heading21">
									<div className="row">
										<div className="col-lg-2 col-md-2 col-sm-2">
										<p className="date">7:30pm</p>
										</div>
										<div className="col-lg-10 col-md-10 col-sm-10">
										<h4 className="panel-title">Team Challenge</h4>
										</div>
									</div>
								</div>
							</div>
							<div className="panel panel-default">
								<div className="panel-heading" role="tab" id="heading22">
									<div className="row">
										<div className="col-lg-2 col-md-2 col-sm-2">
										<p className="date">8:30-10:00pm</p>
										</div>
										<div className="col-lg-10 col-md-10 col-sm-10">
										<h4 className="panel-title">Free Time</h4>
										</div>
									</div>
								</div>
							</div>
							<div className="panel panel-default">
								<div className="panel-heading" role="tab" id="heading23">
									<div className="row">
										<div className="col-lg-2 col-md-2 col-sm-2">
										<p className="date">10:30pm</p>
										</div>
										<div className="col-lg-10 col-md-10 col-sm-10">
										<h4 className="panel-title">Lights Out</h4>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div role="tabpanel" className="tab-pane fade in" id="day3" aria-labelledby="day3-tab">
						<div className="panel-group" id="accordion3" role="tablist" aria-multiselectable="true">
							<div className="panel panel-default">
								<div className="panel-heading" role="tab" id="heading24">
									<div className="row">
										<div className="col-lg-2 col-md-2 col-sm-2">
										<p className="date">7:00am</p>
										</div>
										<div className="col-lg-10 col-md-10 col-sm-10">
										<h4 className="panel-title">Wake Up Call - Get dressed for a run</h4>
										</div>
									</div>
								</div>
							</div>
							<div className="panel panel-default">
								<div className="panel-heading" role="tab" id="heading25">
									<div className="row">
										<div className="col-lg-2 col-md-2 col-sm-2">
										<p className="date">7:30am</p>
										</div>
										<div className="col-lg-10 col-md-10 col-sm-10">
										<h4 className="panel-title">Begin Warm ups for Camp Race</h4>
										</div>
									</div>
								</div>
							</div>
							<div className="panel panel-default">
								<div className="panel-heading" role="tab" id="heading26">
								<div className="row">
										<div className="col-lg-2 col-md-2 col-sm-2">
										<p className="date">8:00-8:45am</p>
										</div>
										<div className="col-lg-10 col-md-10 col-sm-10">
										<h4 className="panel-title">Camp Race (Girls first, then Boys)</h4>
										</div>
									</div>
								</div>
							</div>
							<div className="panel panel-default">
								<div className="panel-heading" role="tab" id="heading27">
									<div className="row">
										<div className="col-lg-2 col-md-2 col-sm-2">
										<p className="date">9:00am</p>
										</div>
										<div className="col-lg-10 col-md-10 col-sm-10">
										<h4 className="panel-title">Breakfast</h4>
										</div>
									</div>
								</div>
							</div>
							<div className="panel panel-default">
								<div className="panel-heading" role="tab" id="heading28">
									<div className="row">
										<div className="col-lg-2 col-md-2 col-sm-2">
										<p className="date">10:00-11:00am</p>
										</div>
										<div className="col-lg-10 col-md-10 col-sm-10">
										<h4 className="panel-title">Speaker</h4>
										</div>
									</div>
								</div>
							</div>
							<div className="panel panel-default">
								<div className="panel-heading" role="tab" id="heading29">
									<div className="row">
										<div className="col-lg-2 col-md-2 col-sm-2">
										<p className="date">11:00-12:00pm</p>
										</div>
										<div className="col-lg-10 col-md-10 col-sm-10">
										<h4 className="panel-title">Free Time</h4>
										</div>
									</div>
								</div>
							</div>
							<div className="panel panel-default">
								<div className="panel-heading" role="tab" id="heading30">
									<div className="row">
										<div className="col-lg-2 col-md-2 col-sm-2">
										<p className="date">12:00-1:00pm</p>
										</div>
										<div className="col-lg-10 col-md-10 col-sm-10">
										<h4 className="panel-title">Lunch</h4>
										</div>
									</div>
								</div>
							</div>
							<div className="panel panel-default">
								<div className="panel-heading" role="tab" id="heading31">
									<div className="row">
										<div className="col-lg-2 col-md-2 col-sm-2">
										<p className="date">1:00-2:00pm</p>
										</div>
										<div className="col-lg-10 col-md-10 col-sm-10">
										<h4 className="panel-title">Team Skit Practices</h4>
										</div>
									</div>
								</div>
							</div>
							<div className="panel panel-default">
								<div className="panel-heading" role="tab" id="heading32">
									<div className="row">
										<div className="col-lg-2 col-md-2 col-sm-2">
										<p className="date">2:00-3:45pm</p>
										</div>
										<div className="col-lg-10 col-md-10 col-sm-10">
										<h4 className="panel-title">Camp Olympic Games</h4>
										</div>
									</div>
								</div>
							</div>
							<div className="panel panel-default">
								<div className="panel-heading" role="tab" id="heading33">
									<div className="row">
										<div className="col-lg-2 col-md-2 col-sm-2">
										<p className="date">3:45-5:00pm</p>
										</div>
										<div className="col-lg-10 col-md-10 col-sm-10">
										<h4 className="panel-title">Free Time / Pool</h4>
										</div>
									</div>
								</div>
							</div>
							<div className="panel panel-default">
								<div className="panel-heading" role="tab" id="heading34">
									<div className="row">
										<div className="col-lg-2 col-md-2 col-sm-2">
										<p className="date">5:30-6:30pm</p>
										</div>
										<div className="col-lg-10 col-md-10 col-sm-10">
										<h4 className="panel-title">Dynamic Warm up / Group Easy Run</h4>
										</div>
									</div>
								</div>
							</div>
							<div className="panel panel-default">
								<div className="panel-heading" role="tab" id="heading35">
									<div className="row">
										<div className="col-lg-2 col-md-2 col-sm-2">
										<p className="date">6:30pm</p>
										</div>
										<div className="col-lg-10 col-md-10 col-sm-10">
										<h4 className="panel-title">Dinner</h4>
										</div>
									</div>
								</div>
							</div>
							<div className="panel panel-default">
								<div className="panel-heading" role="tab" id="heading36">
									<div className="row">
										<div className="col-lg-2 col-md-2 col-sm-2">
										<p className="date">7:30-8:00pm</p>
										</div>
										<div className="col-lg-10 col-md-10 col-sm-10">
										<h4 className="panel-title">CRC / Saucony Presentation</h4>
										</div>
									</div>
								</div>
							</div>
							<div className="panel panel-default">
								<div className="panel-heading" role="tab" id="heading37">
									<div className="row">
										<div className="col-lg-2 col-md-2 col-sm-2">
										<p className="date">8:30-10:00pm</p>
										</div>
										<div className="col-lg-10 col-md-10 col-sm-10">
										<h4 className="panel-title">2 Minute Skits or Karaoke Contest / Camp Dance</h4>
										</div>
									</div>
								</div>
							</div>
							<div className="panel panel-default">
								<div className="panel-heading" role="tab" id="heading38">
									<div className="row">
										<div className="col-lg-2 col-md-2 col-sm-2">
										<p className="date">10:30pm</p>
										</div>
										<div className="col-lg-10 col-md-10 col-sm-10">
										<h4 className="panel-title">Lights Out</h4>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div role="tabpanel" className="tab-pane fade in" id="day4" aria-labelledby="day4-tab">
						<div className="panel-group" id="accordion4	" role="tablist" aria-multiselectable="true">
							<div className="panel panel-default">
								<div className="panel-heading" role="tab" id="heading39">
									<div className="row">
										<div className="col-lg-2 col-md-2 col-sm-2">
										<p className="date">8:00am</p>
										</div>
										<div className="col-lg-10 col-md-10 col-sm-10">
										<h4 className="panel-title">Wake Up Call - Get dressed for a run</h4>
										</div>
									</div>
								</div>
							</div>
							<div className="panel panel-default">
								<div className="panel-heading" role="tab" id="heading40">
									<div className="row">
										<div className="col-lg-2 col-md-2 col-sm-2">
										<p className="date">8:15am</p>
										</div>
										<div className="col-lg-10 col-md-10 col-sm-10">
										<h4 className="panel-title">Dynamic Stretching / Easy Longer Run / Stretching</h4>
										</div>
									</div>
								</div>
							</div>
							<div className="panel panel-default">
								<div className="panel-heading" role="tab" id="heading41">
								<div className="row">
										<div className="col-lg-2 col-md-2 col-sm-2">
										<p className="date">9:30am</p>
										</div>
										<div className="col-lg-10 col-md-10 col-sm-10">
										<h4 className="panel-title">Breakfast</h4>
										</div>
									</div>
								</div>
							</div>
							<div className="panel panel-default">
								<div className="panel-heading" role="tab" id="heading42">
									<div className="row">
										<div className="col-lg-2 col-md-2 col-sm-2">
										<p className="date">10:00-10:45am</p>
										</div>
										<div className="col-lg-10 col-md-10 col-sm-10">
										<h4 className="panel-title">Camp Closing / Awards</h4>
										</div>
									</div>
								</div>
							</div>
							<div className="panel panel-default">
								<div className="panel-heading" role="tab" id="heading43">
									<div className="row">
										<div className="col-lg-2 col-md-2 col-sm-2">
										<p className="date">11:00-12:30pm</p>
										</div>
										<div className="col-lg-10 col-md-10 col-sm-10">
										<h4 className="panel-title">Clean out Dorms / Parent Pick up</h4>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				</div>
				</div>
			</div>
		</section>
        </div>
    );
}

export default Schedule;