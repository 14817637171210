import React from 'react';

const Footer = () => {
	return(
		<div>
			<footer id="footer">
				<div className="container">
					<div className="row">
						<div className="col-lg-3 col-md-3 col-sm-6">
							<h4 className="uppercase">Myers Running Camp '20</h4>
							<p className="small">The 2020 Myers Running Camp will be held from July 10 – July 13, 2020.</p>
							<ul className="list-unstyled list-inline uppercase">
								<li><a href="https://www.facebook.com/groups/132479596768153/" target="_blank" rel="noopener noreferrer canonical"><i className="fa fa-lg fa-facebook" /></a></li>
								<li><a href="https://twitter.com/myersruncamp" target="_blank" rel="noopener noreferrer canonical"><i className="fa fa-lg fa-twitter" /></a></li>
								<li><a href="https://www.instagram.com/myersrunningcamp/" target="_blank" rel="noopener noreferrer canonical"><i className="fa fa-lg fa-instagram" /></a></li>
							</ul>
						</div>
						<div className="col-lg-3 col-md-3 col-sm-6">
							<h4 className="uppercase">FAQ</h4>
							<dl className="faqs">
								<dt>Who can come to camp?</dt>
								<dd className="small">Myers Running Camp is an Ohio Cross Country camp open to all students entering grades 7-12 in the fall of 2020.</dd>
								<dt>Where is camp located?</dt>
								<dd className="small">Camp is located at Camp Oty’Okwa near Old Mans Cave in beautiful Hocking Hills, Ohio.</dd>
								<dt>What if this is my first year running?</dt>
								<dd className="small">Perfect, our camp is designed for any and all ability levels!</dd>
								<dt>How do meals work?</dt>
								<dd className="small">We provide breakfast, lunch, and dinner during camp. The only thing a camper might want to bring is their own snacks and extra drinks.</dd>
							</dl>
						</div>
					</div>
				</div>
			</footer>
			<div className="subfooter">
				<div className="container">
					<div className="row">
						<div className="col-lg-12">
							<ul className="list-unstyled list-inline pull-right uppercase">
								<li><a href="mailto:chadmyers262@gmail.com">Contact the camp directors</a></li>
								<li><a href="tel:1-614-581-2191">Phone: 614-581-2191</a></li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Footer;