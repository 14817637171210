import React from 'react';

var Sponsors = () => {
    return(
        <div>
		   <section id="sponsors">
			<div className="container">
				<div className="row">
					<div className="col-lg-12">
						<h2 className="uppercase">sponsors</h2>
						<p className="lead">Myers Running Camp is proud to have the following sponsors.</p>
						<div id="sponsors-carousel">
							<div className="sponsor">
								<img className="img-responsive" src="img/sponsors/crc_logo.png" alt="Columbus Running Company Logo" />
							</div>
							<div className="sponsor">
								<img className="img-responsive" src="img/sponsors/saucony_logo.png" alt="Saucony Logo" />
							</div>
						</div>
					</div>
				</div>
			</div>
			</section>
        </div>
    );
}

export default Sponsors;