import React from 'react';

var Navigation = () => {
    return(
		<div>
			<nav id="nav-primary" className="navbar navbar-custom" role="navigation">
				<div className="container">
					<div className="navbar-header">
						<button type="button" className="navbar-toggle" data-toggle="collapse" data-target="#nav">
							<span className="sr-only">Toggle navigation</span>
							<span className="icon-bar" />
							<span className="icon-bar" />
							<span className="icon-bar" />
						</button>
						<a className="site-logo" href="index.html"><img src="img/myersrunningcamplogo.svg" className="logo" alt="Myers Running Camp"/></a>
					</div>
					<div className="collapse navbar-collapse" id="nav">
						<ul className="nav navbar-nav navbar-right uppercase">
							<li><a data-toggle="elementscroll" href="#info">About</a></li>
							<li><a data-toggle="elementscroll" href="#speakers">Speakers</a></li>
							<li><a data-toggle="elementscroll" href="#schedule">Schedule</a></li>
							<li><a data-toggle="elementscroll" href="#video">Video</a></li>
							<li><a data-toggle="elementscroll" href="#venue">Location</a></li>
							<li><a data-toggle="elementscroll" href="#register">Register</a></li>
							<li><a data-toggle="elementscroll" href="#gallery">Photos</a></li>
							<li><a data-toggle="elementscroll" href="#sponsors">Sponsors</a></li>
							<li><a data-toggle="elementscroll" href="#footer">Contact</a></li>
						</ul>
					</div>
				</div>
			</nav>
		</div>
    );
}

export default Navigation;