import React from 'react';

var Register = () => {
    return(
        <div>
            <section id="register">
			<div className="container">
				<div className="row">
				<div className="col-lg-12">
					<h2 className="uppercase text-center">registration</h2>
					<p className="lead text-center">Deadline for individuals is July 6, 2020<br />Team registrations (5 or more from a team) is July 6, 2020.</p>
				</div>

				<div className="col-lg-12">                        
					<div id="price-carousel">
						<div className="price-table early-bird">
							<div className="table-header">
								<h3><a href="https://runsignup.com/Race/OH/SouthBloomingville/MyersRunningCamp" target="_blank" rel="noopener noreferrer canonical">Early Bird</a></h3>
							</div>
							<ul className="desc list-unstyled">
								<li>Individual - $260 per camper</li>
								<li>Team - $245 per camper</li>
								<li>10/22/19 through 6/3/2020</li>
							</ul>
						</div>
						<div className="price-table standart">
							<div className="table-header">
								<h3><a href="https://runsignup.com/Race/OH/SouthBloomingville/MyersRunningCamp" target="_blank" rel="noopener noreferrer canonical">Late Registrant</a></h3>
							</div>
							<ul className="desc list-unstyled">
								<li>Individual - $280 per camper</li>
								<li>Team - $265 per camper</li>
								<li>6/4/2020 through 7/3/2020</li>
							</ul>
						</div>
						<div className="price-table vip">
							<div className="table-header">
								<h3><a href="https://runsignup.com/Race/OH/SouthBloomingville/MyersRunningCamp" target="_blank" rel="noopener noreferrer canonical">Last Minute</a></h3>
							</div>
							<ul className="desc list-unstyled">
								<li>Individual - $300 per camper</li>
								<li>Team - $285 per camper</li>
								<li>7/4/2020 through 7/6/2020</li>
							</ul>
						</div>
					</div>
				</div>

				<div className="col-lg-12">
					<h3 className="text-center">Camper Confirmation</h3>
					<p className="register-info text-center">Upon processing of each registration, an initial confirmation notice will be sent by email. A follow-up confirmation notice will also be mailed to campers about 2 weeks before camp. “Important Information for Registered Campers” (including a Parent Consent Form) will also be sent in this email. Each camper should review this document prior to coming to camp.</p>
				</div>

				<div className="col-lg-12">
					<h3 className="text-center">Camper Consent Form</h3>
					<p className="register-info text-center">A parent consent form is REQUIRED for all participants. This parent consent form is a part of the online registration form. Campers will not be permitted to participate without this information completed on the online registration form. We will also have parent consent forms available the day of registration.</p>
				</div>

				<div className="col-lg-12">
					<h3 className="text-center">Refunds</h3>
					<p className="register-info text-center">A full refund will be issued less a $50 administrative fee for any cancellation received at least 2 weeks prior to the start of the camp session, we can not accept any acceptions for this rule. Any cancellations received less than 3 week prior to the start of the camp session will not be eligible for a refund. Cancellation notices and refund requests must be emailed.</p>
				</div>
				
				<div className="col-lg-12 text-center">
					<a className="button button-small button-line-dark html-popup" href="https://runsignup.com/Race/OH/SouthBloomingville/MyersRunningCamp" target="_blank" rel="noopener noreferrer canonical">register now</a>
				</div>
				</div>
			</div>
			</section>
        </div>
    );
}

export default Register;