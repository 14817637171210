import React from 'react';

var Venue = () => {
    return(
        <div>
            <section id="venue">
                <div className="venue">
                    <div className="venue-inner">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-8 col-md-8 col-sm-8">
                                    <h2 className="uppercase">Camp Location</h2>
                                    <p className="lead">Oty'Okwa, Hocking Hills, Ohio</p>
                                    <p className="lead">The camp is centrally located in the Hocking Hills region in the midst of local state parks and preserves.</p>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-4">
                                    <i className="pe-4x pe-7s-map-2" />
                                    <h4 className="uppercase">address</h4>
                                    <p>24799 Purcell Rd, South Bloomingville, OH 43152<br />
                                        <a href="tel:1-740-385-5279">1-740-385-5279</a></p>
                                    <a className="button button-xsmall button-line-light" href="https://campotyokwa.org/" target="_blank" rel="noopener noreferrer canonical">campground information</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Venue;