import React from 'react';

var FunFacts = () => {
    return(
        <div>
			<section id="funfacts">
				<div className="funfacts-inner">
					<div className="container">
						<div className="row">
							<h2 className="uppercase">10 Years of Fun Facts</h2>
							<div className="col-lg-10 col-lg-offset-1">
								<div id="funfacts-carousel">
									<div className="item">
										<i className="pe pe-va pe-4x pe-7s-users" />
										<div className="desc">
										<p className="number">950</p>
										<p className="description">campers</p>
									</div>
								</div>
									<div className="item">
										<i className="pe pe-4x pe-7s-culture" />
										<div className="desc">
										<p className="number">120</p>
										<p className="description">schools</p>
									</div>
								</div>
									<div className="item">
										<i className="pe pe-4x pe-7s-micro" />
										<div className="desc">
										<p className="number">40</p>
										<p className="description">speakers</p>
									</div>
								</div>
									<div className="item">
										<i className="pe pe-4x pe-7s-cup" />
										<div className="desc">
										<p className="number">10</p>
										<p className="description">Watermelons</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				</div>
			</section>
        </div>
    );
}

export default FunFacts;