import React from 'react';

var Speakers = () => {
    return(
        <div>
			<section id="speakers">
				<div className="container">
					<div className="row">
						<div className="col-lg-12">
							<h2 className="uppercase">speakers</h2>
							<p className="lead">Each year, we strive to bring to camp a host of guest speakers and athletes who can inspire, educate, and interact with our campers to the Myers Running Camp an All Ohio Cross Country Camp. We pride ourselves on being able to bring in Olympians, College Coaches, College Athletes, Sports Nutrition professionals, Sports Medical professionals, and motivational speakers.</p>
							<p>Below are a few of our past speakers!</p>
						</div>
						<ul id="list-speaker" className="list-unstyled"> 
							<li className="col-lg-3 col-md-3 col-sm-4">
								<div className="speaker">
									<figure className="effect-ming">
										<img className="img-speaker-responsive" src="img/speakers/Molly_Seidel-usa.jpg" alt="Photo of Molly Seidel" />
										<figcaption>
											<span><a className="html-popup" href="speakers/mseidel-detail.html"><img className="img-responsive" src="img/plus.png" alt="press to show details" /></a></span>
										</figcaption>			
									</figure>
									<div className="caption text-center">
										<h4>Molly Seidel</h4>
										<p className="company">Saucony Freedom Track Club</p>
									</div>   
								</div>
							</li>
							<li className="col-lg-3 col-md-3 col-sm-4">
								<div className="speaker">
									<figure className="effect-ming">
										<img className="img-speaker-responsive" src="img/speakers/nathanbrannen.jpg" alt="Photo of Nate Brannen" />
										<figcaption>
											<span><a className="html-popup" href="speakers/nbrannen-detail.html"><img className="img-responsive" src="img/plus.png" alt="press to show details" /></a></span>
										</figcaption>			
									</figure>
									<div className="caption text-center">
										<h4>Nate Brannen</h4>
										<p className="company">3 time Canadian Olympian in the 1500 meter run</p>
									</div>   
								</div>
							</li>
							<li className="col-lg-3 col-md-3 col-sm-4">
								<div className="speaker">
									<figure className="effect-ming">
										<img className="img-speaker-responsive" src="img/speakers/jason-ordway.jpg" alt="Photo of Jason Ordway" />
										<figcaption>
											<span><a className="html-popup" href="speakers/jordway-detail.html"><img className="img-responsive" src="img/plus.png" alt="press to show details" /></a></span>
										</figcaption>			
									</figure>
									<div className="caption text-center">
										<h4>Jason Ordway</h4>
										<p className="company">University of Dayton Director of Track & Field/Cross Country</p>
									</div>   
								</div>
							</li>
							<li className="col-lg-3 col-md-3 col-sm-4">
								<div className="speaker">
									<figure className="effect-ming">
										<img className="img-speaker-responsive" src="img/speakers/tom-chorny.jpg" alt="Photo of Tom Chorny" />
										<figcaption>
											<span><a className="html-popup" href="speakers/tchorney-detail.html"><img className="img-responsive" src="img/plus.png" alt="press to show details" /></a></span>
										</figcaption>			
									</figure>
									<div className="caption text-center">
										<h4>Tom Chorny</h4>
										<p className="company">Director of Track & Field at Miami University</p>
									</div>   
								</div>
							</li>
						</ul>
					</div>
				</div>
			</section>
        </div>
    );
}

export default Speakers;