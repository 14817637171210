import React from 'react';

const Hightlight = () => {
	return (
		<div>
			<section id="highlight">
				<div className="container-fluid">
					<div className="row">
						<div id="left" className="left col-md-8 text-right">
							<h2>Next Years Camp Starts In!</h2>
							<p>July 10-13, 2020</p>
						</div>
						<div id="right" className="col-md-4 text-left">
							<div id="countdown" />
						</div>
					</div>
				</div>
			</section>
		</div>
	);
}

export default Hightlight;