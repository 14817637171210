import React from "react";

// Component Imports
import Header from "./components/header/header";
import Slider from "./components/full-screen-slider/full-screen-slider";
import Highlight from "./components/highlight/highlight";
import About from "./components/about/about";
import Speakers from "./components/speakers/speakers";
import Schedule from "./components/schedule/schedule";
import Video from "./components/video/video";
import Venue from "./components/venue/venue";
import FunFacts from "./components/funfacts/funfacts";
import Register from "./components/register/register";
import Photos from "./components/gallery/gallery";
import Sponsors from "./components/sponsors/sponsors";
import Footer from "./components/footer/footer";

// SCSS Imports
import "./App.scss";

function App() {
  return (
    <div className="App">
      <Header />
      <Slider />
      <Register />
      <Highlight />
      <About />
      <Speakers />
      <Schedule />
      <Video />
      <Venue />
      <FunFacts />
      <Photos />
      <Sponsors />
      <Footer />
    </div>
  );
}

export default App;
