import React from 'react';
import Navigation from "../navigation/nav";

var Header = () => {
    return(
        <div>
            <header>
                <Navigation />
            </header>
        </div>
    );
}

export default Header;