import React, { Component } from 'react';
import Gallery from "react-grid-gallery";

class Photos extends Component {
	constructor(props) {
		super(props);
		this.state = {
			photos: [],
			isLoading: false
		};
	}

	componentDidMount() {
		this.setState({
			isLoading: true
		});
		
		let newPhotosArray = [];
		
		fetch('http://www.myersrunningcamp.com/wp-json/wp/v2/media?per_page=35', {
			crossDomain:true,
			method: 'GET',
			headers: {
				'Content-Type':'application/json'
			}
		   })
			.then(response => response.json())
			.then(function(response) {
				response.forEach(element => {
					let photoObject = {
						"src": element.source_url,
						"thumbnail": element.source_url,
						"thumbnailWidth": element.media_details.width,
						"thumbnailHeight": element.media_details.height,
						"isSelected": false,
						"caption": ""
					};

					newPhotosArray.push(photoObject);
				});
				return newPhotosArray;
			})
			.then(newPhotosArray => this.setState({
				photos: newPhotosArray,
				isLoading: false
			}));
	}

	render() {
		return (
			<div>
				<section id="gallery">
					<div className="container">
						<div className="row">
							<div className="col-lg-12">
								<h2 className="uppercase">Camp Photos</h2>
								<p className="lead">Below are some photos from all previous camps!</p>
								{this.state.isLoading ? (
									<p>Loading Photos....</p>
								) : (
									<Gallery images={this.state.photos} />
								)}
							</div>
						</div>
					</div>
				</section>
			</div>
		);
	}
   }

export default Photos;