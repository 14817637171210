import React from 'react';

var Slider = () => {
    return(
        <div>
            <div className="tp-banner-container">
			<div className="tp-banner">
				<ul>	
					<li data-transition="slidevertical" data-slotamount={1} data-masterspeed={1000} data-thumb="" data-saveperformance="off" data-title="Slide">
						<img src="img/slide1.jpg" alt="Photo of campers running" data-bgposition="center top" data-bgfit="cover" data-bgrepeat="no-repeat" />
						<div className="tp-caption light_heavy_70_shadowed lfb ltt tp-resizeme" data-x="center" data-hoffset={250} data-y="center" data-voffset={-70} data-speed={600} data-start={800} data-easing="Power4.easeOut" data-splitin="none" data-splitout="none" data-elementdelay="0.01" data-endelementdelay="0.1" data-endspeed={500} data-endeasing="Power4.easeIn" style={{zIndex: 2, maxWidth: 'auto', maxHeight: 'auto', whiteSpace: 'nowrap'}}>
						</div>
						<div className="tp-caption light_medium_30_shadowed lfb ltt tp-resizeme" data-x="center" data-hoffset={205} data-y="center" data-voffset={-10} data-speed={600} data-start={900} data-easing="Power4.easeOut" data-splitin="none" data-splitout="none" data-elementdelay="0.01" data-endelementdelay="0.1" data-endspeed={500} data-endeasing="Power4.easeIn" style={{zIndex: 3, maxWidth: 'auto', maxHeight: 'auto', whiteSpace: 'nowrap'}}>
						</div>
					</li>
					<li data-transition="slidevertical" data-slotamount={1} data-masterspeed={1000} data-thumb="" data-saveperformance="off" data-title="Slide">
						<img src="img/slide2.jpg" alt="Photo of myers running camp runners" data-bgposition="center top" data-bgfit="cover" data-bgrepeat="no-repeat" />
						<div className="tp-caption light_heavy_70_shadowed lfb ltt tp-resizeme" data-x="center" data-hoffset={270} data-y="center" data-voffset={-70} data-speed={600} data-start={800} data-easing="Power4.easeOut" data-splitin="none" data-splitout="none" data-elementdelay="0.01" data-endelementdelay="0.1" data-endspeed={500} data-endeasing="Power4.easeIn" style={{zIndex: 2, maxWidth: 'auto', maxHeight: 'auto', whiteSpace: 'nowrap'}}>
						</div>
						<div className="tp-caption light_medium_30_shadowed lfb ltt tp-resizeme" data-x="center" data-hoffset={230} data-y="center" data-voffset={0} data-speed={600} data-start={900} data-easing="Power4.easeOut" data-splitin="none" data-splitout="none" data-elementdelay="0.01" data-endelementdelay="0.1" data-endspeed={500} data-endeasing="Power4.easeIn" style={{zIndex: 3, maxWidth: 'auto', maxHeight: 'auto', whiteSpace: 'nowrap'}}>
						</div>
					</li>
				</ul>		
				<div className="tp-bannertimer" /></div>
			</div>
        </div>
    );
}

export default Slider;